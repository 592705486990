export default function Custom404() {
    return (
        <div style={{ textAlign: 'center' }}>
            <div style={{ marginTop: '10%' }}>
                <h1>An error <span style={{ color: '#0D66C2' }}>404</span> occurred on server</h1>
                <p>
                    This is not the web page you are looking for. For more information please contact support contact@sketshare.com
                </p>
            </div>
        </div>
    )
}